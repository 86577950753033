function loadBlockingSpinner() {
    var ele = $('.content-main', window.parent.document);
    loadSpinnerOnElement(ele);
}

function loadSelectionSpinner() {
    var ele = $('#slickGrid');
    loadSpinnerOnElement(ele);
}

function hideSelectionSpinner() {

    hideBlockingSpinner();
}

function hideBlockingSpinner() {

    $('.k-loading-mask').remove();
}

function loadSpinnerOnElement(element) {

    kendo.ui.progress(element, true);
}

